import { Component, OnInit } from '@angular/core';
import { DataAccessService } from './data-access.service';

interface City {
  name: string,
  code: string
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  constructor(private service: DataAccessService) { }

  ngOnInit() { }
}
