import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessService } from '../data-access.service';
declare let alertify;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit {
 
  myDate: Date; 
  plants; 
  isLogin = false; 
  isreset = false;  
  isNewReset = false;  
  
 
 
  client_code = "GMP22031" ; 

 

  loginData: any = [];
  new_password = '';    
  confirm_password = '';
  constructor(public service: DataAccessService, private router: Router) {
    setInterval(() => { this.myDate = new Date(); }, 1);    
  }
 
    
  ngOnInit() {
    this.validateUser();
    this.getPlant(); 
   
  }



  username ='master';
  password ='master';
 

  getPlant() {
 
     this.service.getData('https://cpplgmp.com/admin/api/clients/client_data_without_token.php?type=get_client_by_id&client_code=' + this.client_code).subscribe(response => {
      this.plants = response;
    });
  }
  


  checkLogin(formData) {7
    if (!formData.valid) {
      alertify.error('All fields are required!');
      return;
    }

    this.loginData = formData.value;
    const data = formData.value;
    this.service.login('checkLogin.php?type=newLogin&username=' + data['username'] + '&password=' + data["password"] + '&plant_id=' + data["plant_id"], JSON.stringify(data)).subscribe(response => {
      
      if (response['status'] === 'success') {
      
         console.log(response);
        
        if (response['ISNEW'] == 'YES') {
          this.isNewReset = true;
          localStorage.setItem('plant_id', response['plant_id']);
          localStorage.setItem('client_code', response['client_code']);
        } else {
          
          localStorage.setItem('token', response['token']);
          localStorage.setItem('plant_id', response['plant_id']);
          localStorage.setItem('is_corporate', response['is_corporate']);
          localStorage.setItem('department', response['department']);
          localStorage.setItem('logo_path', response['logo_path']);
          localStorage.setItem('licence_no', response['licence_no']);
          localStorage.setItem('loger_id', response['loger_id']);
          

          localStorage.setItem('designation', response['designation']);
          localStorage.setItem('user', response['user']);
          localStorage.setItem('checker', response['checker']);
          localStorage.setItem('approver', response['approver']);
          localStorage.setItem('qms_approver', response['qms_approver']);
          localStorage.setItem('dept_head', response['dept_head']);
          localStorage.setItem('username', response['username']);
          
        if (data['username']=='master')
        {
          localStorage.setItem('user_department','All departments');
        }
    
          else{
          localStorage.setItem('user_department', response['data']['user_rights'][0].department);
          }
          this.service.username = response['username'];
          this.service.userChange();
          localStorage.setItem('user_no', 'gmpdemo1');
          localStorage.setItem('type', response['type']);
          localStorage.setItem('company_name', 'GMP Software Pvt Ltd');
          localStorage.setItem('login', 'yes');
          localStorage.setItem('client_code', response['client_code']);
           
          this.getPlantsFromMasterData();
          this.isLogin = true;
          this.service.getCommonDetails();
          let resp:any = response ;
          localStorage.setItem('user_rights', resp?.data?.user_rights);

        }
      } else {
        alertify.error(response['status'] );
        
        this.isLogin = false;
      }
    });
  }


   
  getPlantsFromMasterData() {
     this.service.getData('https://cpplgmp.com/admin/api/clients/client_data_without_token.php?type=get_client_data_by_id&id=' + localStorage.getItem("plant_id")).subscribe(response => {
      localStorage.setItem('client_info', JSON.stringify(response));
   
    });
  }



  validateUser() {
    if (localStorage.getItem('token') !== null && localStorage.getItem('department') !== null) {
      this.isLogin = true;
      this.getPlantsFromMasterData();
      this.service.getCommonDetails();
      
    } else {
      this.isLogin = false;
    }
  }

  reset(data) {
    if (!data.valid) {
      alertify.error('All fields are required!');
      return;
    }
    this.service.post('it/password.php?type=resetPassword', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] == 'success') {
        alertify.success('Password reset Request send for Approval!');
        this.isreset = false;
        data.reset(); 
      } else {
        alertify.error('Failed: An error occured, please try again!');
      }
    });
  }

  resetPassword(data) {
    if (!data.valid) {
      alertify.error('All fields are required!');
      return;
    }
    let temp = data.value;

    if (temp['new_password'] !== temp['confirm_password']) {
      alertify.error('Invalid Confirm Password!');
      return;
    }

    temp['emp_id'] = this.loginData['username'];
    this.service.post('checkLogin.php?type=resetPassword', JSON.stringify(temp)).subscribe(response => {
      if (response['status'] == 'success') {
        alertify.success('Password has been Updated!');
        this.isNewReset = false;
        data.reset();
      } else {
        alertify.error('Failed: An error occured, please try again!');
      }
    });
  }
}
