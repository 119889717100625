  import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { DataAccessService } from './data-access.service';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { MultiSelectModule } from 'primeng/multiselect';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
 import { FooterComponent } from './footer/footer.component'; 
  import { ModalModule } from './_modal';
 import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
 import { SharedModule } from './shared/shared.module';
   

const routes: Routes = [
  { path: '', component: DashboardComponent},
    
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    NavbarComponent,
    FooterComponent],
   imports: [
    BrowserModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ClarityModule,
    FormsModule,
    ReactiveFormsModule ,
    
    HttpClientModule,
    BrowserAnimationsModule,
    QuicklinkModule,
    MultiSelectModule,
    NgxDocViewerModule,
    LoadingBarHttpClientModule,
    PdfViewerModule,
    ModalModule, 
    SharedModule,
    
    RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy })
  ],
  exports:[
    ReactiveFormsModule,
  ],
  providers: [
    DataAccessService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
