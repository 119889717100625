import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({ 
  providedIn: 'root'
})
export class DataAccessService { 
  [x: string]: any; 
 
      domain = "https://cpplgmp.com/php/phpdevlop/" // Only For Development Purpose
      // domain = "https://cpplgmp.com/php/" // Only For Production Purpose
     
      
   
  public url = this.domain + 'gmptotal/';
  // public url = this.domain + 'gmptotal1/'; //saipro godaddy only
  customer_code = "C-001"; 
  main_client_code = "GMP22002";
  grades: any = [];
  observableGrade; 
  current_route: string;
  departments: any = [];
  observableDepartment;
  approver=false;         
  checker=false; 
  units: any = []; 
  observableUnit; 

  dosages: any = [];
  observableDosage;

  states: any = []; 
  observableState;

  vendors: any = [];
  observableVendor; 

  styles: any = [];
  observableStyle;

  storages: any = [];
  observableStorage;

  shelfs: any = [];
  observableShelf;

  plants: any = [];
  observablePlant;

  gst: any = [];
  observableGst;


  fgTypes: any = [];
  observableFGTypes;

  materialTypes: any = [];
  observableMaterialTypes;

  isMobile = false;

  username = '';
  observableUsername;
  constructor(private http: HttpClient, private router: Router) {
    this.username = localStorage.getItem('username');


    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 850) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.observableGrade = new BehaviorSubject(this.grades);
    this.observableDepartment = new BehaviorSubject(this.departments);
    this.observableUnit = new BehaviorSubject(this.units);
    this.observableDosage = new BehaviorSubject(this.dosages);
    this.observableState = new BehaviorSubject(this.states);
    this.observableVendor = new BehaviorSubject(this.vendors);
    this.observableStyle = new BehaviorSubject(this.styles);
    this.observableStorage = new BehaviorSubject(this.storages);
    this.observableShelf = new BehaviorSubject(this.shelfs);
    this.observableGst = new BehaviorSubject(this.gst);
    this.observableMaterialTypes = new BehaviorSubject(this.materialTypes);
    this.observableFGTypes = new BehaviorSubject(this.fgTypes);
    this.observablePlant = new BehaviorSubject(this.plants);

    this.observableUsername = new BehaviorSubject(this.username);
  }

  get(url) {
    // console.log(this.router.url);
    this.current_route = this.router.url.replace('/', '-');
    url = this.url + url + '&description=' + this.current_route + '&token=' + localStorage.getItem('token') + '&user_no=gmpdemo1' + '&plant_id=' + localStorage.getItem('plant_id');
    return this.http.get(url);
  }
  
  getData(url) {
    return this.http.get(url);
  }

  post(url, postData) {
    //   console.log(this.router.url);
    this.current_route = this.router.url.replace('/', '-');
    url = this.url + url + '&description=' + this.current_route + '&token=' + localStorage.getItem('token') + '&user_no=gmpdemo1' + '&plant_id=' + localStorage.getItem('plant_id');
    return this.http.post(url, postData);
  }
  login(url, postData) {
    url = this.url + url + '&token=' + localStorage.getItem('token') + '&user_no=gmpdemo1';
    return this.http.post(url, postData);
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  open(url) {
    window.open(this.url + url + '&token=' + localStorage.getItem('token') + '&user_no=gmpdemo1' + '&plant_id=' + localStorage.getItem('plant_id'));
  }

  getCommonDetails() {
    let url = this.url + 'common.php?type=getCommonDetails' + '&token=' + localStorage.getItem('token') + '&user_no=gmpdemo1' + '&plant_id=' + localStorage.getItem('plant_id');
    this.http.get(url).subscribe(response => {
      this.grades = response['grades'];
      this.gradeChange();

      this.departments = response['departments'];
      this.departmentChange();

      this.units = response['units'];
      this.unitChange();

      this.dosages = response['dosages'];
      this.dosageChange();

      this.states = response['states'];
      this.stateChange();

      this.vendors = response['vendors'];
      this.vendorChange();

      this.styles = response['styles'];
      this.styleChange();

      this.storages = response['storages'];
      this.storageChange();

      this.shelfs = response['shelfs'];
      this.shelfChange();

      this.gst = response['gst'];
      this.gstChange();

      this.materialTypes = response['material_types'];
      this.materialTypesChange();

      this.fgTypes = response['fg_types'];
      this.fgTypesChange();

      //  this.plants = response['plants'];
      //  this.plantChange();
    });
    this.getPlants();
  }
  access  =false;
  checkUserAccess(componentName,action)
  {
    console.log(componentName);
    let url = this.url + 'checkLogin.php?type=UserAccess&data='+ localStorage.getItem('emp_id') + '&ComponentName=' + componentName +'&Action=' + action;
    this.http.get(url).subscribe(response => {
     console.log(response)
     this.checker = response[0]['ischecker'];
     this.approver = response[0]['isapprover'];
    if(response['status']=='sucess')
    {
     if(response[0]['ischecker']=='true' && response[0]['isapprover']=='true' && response[0]['isuser']=='true')
      {
        // this.router.navigate(['/dashboard']);
        this.access = true;
      }
     if(response['status']=='sucess' && response[0]['ischecker']=='true')
      {
        // this.router.navigate(['/qc/sampling/raw/checking']);
        this.access = true;
      }
     if(response['status']=='sucess' && response[0]['isapprover']=='true')
      {
        // this.router.navigate(['/qc/sampling/raw/approval']);
        this.access = true;
      }
     if(response[0]['additional'][1]['department']=='Purchase' && response[0]['additional'][1]['user']=='Yes')
      {
        // this.router.navigate(['/purchase/indend/raw/new']);
        this.access = true;
      }
      else
      {
        // this.router.navigate(['/']);
        this.access = false;
      }
    }
      else
      {
        this.router.navigate(['/']);
      }
    });

  }
  materialTypesChange() {
    this.observableMaterialTypes.next(this.materialTypes);
  }

  fgTypesChange() {

    this.observableFGTypes.next(this.fgTypes);
  }
  gradeChange() {
    this.observableGrade.next(this.grades);
  }

  departmentChange() {
    this.observableDepartment.next(this.departments);
  }

  unitChange() {
    this.observableUnit.next(this.units);
  }

  dosageChange() {
    this.observableDosage.next(this.dosages);
  }

  stateChange() {
    this.observableState.next(this.states);
  }

  vendorChange() {
    this.observableVendor.next(this.vendors);
  }

  styleChange() {
    this.observableStyle.next(this.styles);
  }

  storageChange() {
    this.observableStorage.next(this.storages);
  }

  shelfChange() {
    this.observableShelf.next(this.shelfs);
  }

  userChange() {
    this.observableUsername.next(this.username);
  }
  gstChange() {
    this.observableGst.next(this.gst);
  }
  plantChange() {
    this.observablePlant.next(this.plants);
  }
  getPlants() {
    // this.service.get('master/plant.php?type=getPlant&show_corporate=1').subscribe(response => {
    //   this.plants = response;
    // }); 
    // this.http.get('http://103.171.181.38/~gmpserver/public_html/admin/api/clients/client_data_without_token.php?type=get_client_by_id&client_code=' + this.main_client_code).subscribe(response => {
    this.http.get('https://cpplgmp.com/admin/api/clients/client_data_without_token.php?type=get_client_by_id&client_code=' + this.main_client_code).subscribe(response => {
      this.plants = response;
      this.plantChange();
    });

  }

  getStorages() {
    let url = this.url + 'qa/master.php?type=getStorageConditions' + '&token=' + localStorage.getItem('token') + '&user_no=gmpdemo1';
    this.http.get(url).subscribe(response => {
      this.storages = response['storages'];
      this.storageChange();
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getPlantConfigFields(field_type) {
    let value = '';
    let data = localStorage.getItem('client_info');
    let json_data = JSON.parse(data);
    if (json_data == null || json_data == '') {
      return null;
    }
    switch (field_type) {
      case "plant_type":
        value = json_data[0]['plant_type']
        break;
      case "software_license_no":
        value = json_data[0]['software_license_no']
        break;
      case "software_type":
        value = json_data[0]['software_type']
        break;
      case "plant_name":
        value = json_data[0]['unit_name']
        break;
      case "plant_id":
        value = localStorage.getItem('plant_id');
        break;
      case "main_client_code":
        value = this.main_client_code;
      case "plant_logo":
        value = json_data[0]['logo_rect']
    }
    return value;

  }


  allRecord(){
    // return this.http.get('http://103.171.181.38/~gmpserver/public_html/php/phpdevlop/security/gatepass.php?type=test');
    return this.http.get('https://cpplgmp.com/php/phpdevlop/security/gatepass.php?type=test');
  }

}
