import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordPromptModalComponent } from './password-prompt-modal/password-prompt-modal.component';



@NgModule({
    imports: [CommonModule],
    declarations: [PasswordPromptModalComponent],
    exports: [PasswordPromptModalComponent]
})
export class ModalModule { }