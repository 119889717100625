import { Component, OnInit } from '@angular/core';
declare let alertify: any;
import { DataAccessService } from '../data-access.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  username = '';
  plant_logo='assets/gmp.jpg';
  myDate: Date;
  plant_type: string;
  plant_name: string;
  salary: any;
  constructor(public service: DataAccessService) {
    setInterval(() => {
      this.myDate = new Date();
      if(this.plant_name =='' || this.plant_name==undefined || this.plant_name== null){
       
      }
    
      if(this.plant_logo == null || this.plant_logo == undefined || this.plant_logo == ""){
        this.loadPlantInfo();    
      }

    }, 1);




    const callApi = () => {
       console.log('Running the API call at 5:20pm');

       this.service.get('hr/employee.php?type=update_salary').subscribe(response => {
           this.salary = response;
           });
    };
    
    // Function to calculate the time until 5:20 pm and run the function
    const runAtSpecificTime = (targetTime, task) => {
      const currentTime = new Date();
      const targetTimeToday = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        targetTime.getHours(),
        targetTime.getMinutes(),
        0  
      );
      let timeToWait = targetTimeToday.getTime() - currentTime.getTime();
      if (timeToWait < 0) {
        
        targetTimeToday.setDate(targetTimeToday.getDate() + 1);
        timeToWait = targetTimeToday.getTime() - currentTime.getTime();
      }
    
      setTimeout(() => {
        task();
         
        runAtSpecificTime(targetTime, task);
      }, timeToWait);
    };
    
     
    const targetTime = new Date();
    targetTime.setHours(17);  
    targetTime.setMinutes(24);  
    
     runAtSpecificTime(targetTime, callApi);
    
    
    // setInterval(() => {
     

    //     this.service.get('hr/employee.php?type=update_salary').subscribe(response => {
    //       this.salary = response;
    //     });
    
    // },10000); 
    // },5 * 60 * 60 * 1000); 
    // }, ); 

    this.service.observableUsername.subscribe(response => {
      console.log(response);
      this.username = this.service.username;      
    });
   

  }


  logo_path;
  ngOnInit() {



    this.loadPlantInfo();
  }
  loadPlantInfo() {


    setTimeout(() => {
      this.plant_type = this.service.getPlantConfigFields('plant_type');
      this.plant_name = this.service.getPlantConfigFields('plant_name');
    }, 1000);

     this.plant_logo = localStorage.getItem('logo_path');

    this.logo_path = 'https://cpplgmp.com/php/phpdevlop/gmptotal/logos/'+this.plant_logo;
  }
}
